<script setup>
import {defineProps} from 'vue';
import useRotas from '../../Composables/useRotas';

const props = defineProps({
    contagens: Object,
    categorias: Array,
});

const route = useRotas();

const getCount = (type, category, subCategory) => {
    const correctCount = props.contagens?.categorias?.filter((contagem) => (
        contagem?.tipo === type
        && contagem?.categoria === category
        && contagem?.subCategoria === subCategory
    ))[0];
    return correctCount?.total || 0;
};

</script>

<template>
    <div class="q-pt-md q-pb-md"
         style="text-align: center; cursor: pointer;"
         :style="$q.screen.lt.sm ? 'min-width: 110px; max-width: 110px;' : 'min-width: 150px; max-width: 150px;'"
         >
        <div >

            <q-btn round
                   color="secondary"
                   icon="mdi-format-list-bulleted-square"
                   text-color="primary"
                   size="24px"
                   class="q-mb-sm shadow-5"
                   @click="$inertia.visit(route('home'))"
            />

        </div>
        <div :style="$q.screen.lt.sm ? 'font-size: 13px' : ''">
            Todos os Leilões
        </div>
    </div>

    <div class="q-pt-md q-pb-md"
         v-for="categoria of categorias"
         :key="categoria.tipo + '-' + categoria.slug"
         v-show="getCount(categoria.tipo, categoria.slug, null) > 0"
         style="text-align: center; cursor: pointer;"
         :style="$q.screen.lt.sm ? 'min-width: 110px; max-width: 110px;' : 'min-width: 150px; max-width: 150px;'"
    >
        <div>

            <q-btn round
                   color="secondary"
                   :icon="categoria.icone_site"
                   text-color="primary"
                   size="24px"
                   class="q-mb-sm shadow-5"
                   @click="$inertia.visit(route('tipos.categorias.categoria', {tipo: categoria.tipo, categoria: categoria.slug}))"
            />

        </div>
        <div :style="$q.screen.lt.sm ? 'font-size: 13px' : ''">
            {{ categoria.nome }}
        </div>
    </div>

</template>
