<script setup>
import { usePage } from '@inertiajs/inertia-vue3';
import Filtros from '../Componentes/Home/Filtros';
import FiltrosTopo from "@/Componentes/Home/FiltrosTopo.vue";
import HomeLayout from '../Layouts/HomeLayout';
import { defineProps, ref, computed, watch, onMounted } from 'vue';
import ListaCategorias from '../Componentes/Home/ListaCategorias';
import ListaCategorias2 from '../Componentes/Home/ListaCategorias2';
import { useQuasar } from 'quasar';
import ListaLeiloes from '../Componentes/Home/ListaLeiloes';
// import ListaCarouselComitentes from "../Componentes/Home/ListaCarouselComitentes";
import ListaCarouselLotesComitentes from "../Componentes/Home/ListaCarouselLotesComitentes";
import qs from 'qs';
import ListaLotesSemLeilao from '../Componentes/Home/ListaLotesSemLeilao';
import CardLeilaoDestaque from '../Componentes/Home/CardLeilaoDestaque';

const props = defineProps({
    contagens: Object,
    categorias: Array,
    banners: Array,
    destaques: Array,
    rotaDadosLeiloes: String,
    rotaDadosLotes: String,
    rotaDadosLotesComitentes: String,
    textoHeader: String,
    textosPagina: Object,
    filtros: Object,
    filtrosOpcoes: Object,
    tipoCategoria: String,
    categoria: String,
});

const dominio_venda_direta_ativo = computed(()=> usePage().props.value.dominio_venda_direta_ativo);

const cliente = computed(() => usePage().props.value.auth.cliente)
const logado = computed(() => usePage().props.value.auth?.autenticado);

const $q = useQuasar();
const showDialog = ref(false);

const toggleFilter = () => {
    showDialog.value = !showDialog.value
};

const tab = computed(() => (props.textosPagina.tipo || props.textosPagina.categoria || props.textosPagina.subCategoria) ? 'lotes' : 'leiloes');
const infiniteScrollLeiloes = ref(null);
const infiniteScrollLotes = ref(null);

const tudoCarregadoLeiloes = ref(false);
const leiloesCarregados = ref([]);
const tudoCarregadoLotes = ref(false);
const lotesCarregados = ref([]);

const lotesComitentesCarregados = ref([]);
// const lotesComitentesCarregadosOrdenados = ref([]);

const loadDataLeiloes = async (index, done) => {
    if (!tudoCarregadoLeiloes.value){
        const leiloes = await (await fetch(props.rotaDadosLeiloes+ '?' + qs.stringify({
            page: index,
            type: (dominio_venda_direta_ativo.value) ? 'venda_direta' : 'leilao',
            ...props.filtros
        }), {
            method: 'GET',
            credentials: 'include'
        })).json();
        leiloesCarregados.value.push(...leiloes)
        if (leiloes.length === 0){
            tudoCarregadoLeiloes.value = true;
            infiniteScrollLeiloes.value.stop()
        }
        done()
    } else {
        infiniteScrollLeiloes.value.stop()
        done()
    }
}

const loadDataLotes = async (index, done) => {
    if (!tudoCarregadoLotes.value){
        const lotes = await (await fetch(props.rotaDadosLotes+ '?' + qs.stringify({
            page: index,
            type: (dominio_venda_direta_ativo.value) ? 'venda_direta' : 'leilao',
            ...props.filtros
        }), {
            method: 'GET',
            credentials: 'include'
        })).json();
        lotesCarregados.value.push(...lotes)

        if (lotes.length === 0){
            tudoCarregadoLotes.value = true;
            infiniteScrollLotes.value.stop()
        }
        done()
    } else {
        infiniteScrollLotes.value.stop()
        done()
    }
}

const loadDataLotesComitentes = async (index) => {

    const lotes = await (await fetch(props.rotaDadosLotesComitentes+ '?' + qs.stringify({
        page: index,
        type: (dominio_venda_direta_ativo.value) ? 'venda_direta' : 'leilao',
        ...props.filtros
    }), {
        method: 'GET',
        credentials: 'include'
    })).json();
    lotesComitentesCarregados.value.push(...lotes)

}

watch(props, () => {
    tudoCarregadoLeiloes.value = false;
    tudoCarregadoLotes.value = false;
    leiloesCarregados.value = [];
    lotesCarregados.value = [];
    if(tab.value === 'leiloes'){
        infiniteScrollLeiloes.value.reset()
        infiniteScrollLeiloes.value.resume()
        infiniteScrollLeiloes.value.trigger()
    } else {
        infiniteScrollLotes.value.reset()
        infiniteScrollLotes.value.resume()
        infiniteScrollLotes.value.trigger()
    }
})

onMounted(() =>{


    loadDataLotesComitentes(0);

    if(tab.value === 'leiloes'){
        infiniteScrollLeiloes.value.trigger();
    } else {
        infiniteScrollLotes.value.trigger();
    }
})

const slideDestaques = ref(1);

const destaques = computed(() => props.destaques.map((d) => d.leilao))

const thumbStyle = {
    borderRadius: '7px',
    backgroundColor: 'var(--q-primary)',
    opacity: 0.75,
    height: '5px',
};

const barStyle = {
        borderRadius: '9px',
        backgroundColor: 'var(--q-primary)',
        height: '5px',
        opacity: 0.2,
};

</script>

<template>
    <HomeLayout :title="(textoHeader || 'Home')" :banners="banners">
        <q-page>
            <div class="flex row full-width q-pt-md q-pl-lg justify-center items-center" style="font-size: 18px; ">
                Navegue por nossas categorias
            </div>
            <div class="flex full-width no-wrap">
                <q-scroll-area class="full-width"
                               style="height: 170px"
                               :thumb-style="thumbStyle"
                               :bar-style="barStyle" >
                    <div class="row no-wrap justify-center">
                        <lista-categorias2  :contagens="contagens" :categorias="categorias" />
                    </div>
                </q-scroll-area>
            </div>
            <div
                v-if="tab === 'leiloes'"
                class="flex column justify-center content-stretch items-center">
                <h1 class="text-center q-mb-none q-mx-sm q-mt-none" :class="$q.screen.gt.xs ? 'text-h4' : 'text-h5'">
                    Oportunidades em destaque
                </h1>
                <p  class="text-center q-mb-none">

                    {{ (dominio_venda_direta_ativo) ? 'Envie sua Proposta!' : 'Esse é o lance!' }}

                </p>
            </div>
            <div
                v-if="tab === 'leiloes'" class="flex row full-width" style="justify-content: stretch">
                <q-carousel
                    swipeable
                    animated
                    :arrows="$q.screen.gt.xs && $q.screen.lt.lg"
                    :navigation="$q.screen.lt.sm"
                    autoplay
                    class="full-width"
                    :control-color="$q.screen.gt.xs ? 'secondary' : 'primary'"
                    v-model="slideDestaques"
                    :height="$q.screen.lt.sm ? '17em' : '20em'"
                    transition-prev="slide-right"
                    transition-next="slide-left"
                    infinite
                >
                    <!-- Desktop -->
                    <q-carousel-slide v-if="$q.screen.gt.md" :name="1" :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row ">
                        <card-leilao-destaque v-if="destaques[0]" :leilao-init="destaques[0]"/>
                        <card-leilao-destaque v-if="destaques[1]" :leilao-init="destaques[1]"/>
                        <card-leilao-destaque v-if="destaques[2]" :leilao-init="destaques[2]"/>
                    </q-carousel-slide>
                    <!-- Desktop /-->

                    <!-- Tablet -->
                    <q-carousel-slide v-if="$q.screen.gt.sm && $q.screen.lt.lg" :name="1" :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row">
                        <card-leilao-destaque v-if="destaques[0]" :leilao-init="destaques[0]"/>
                        <card-leilao-destaque v-if="destaques[1]" :leilao-init="destaques[1]"/>
                    </q-carousel-slide>
                    <q-carousel-slide v-if="$q.screen.gt.sm && $q.screen.lt.lg" :name="2" :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row">
                        <card-leilao-destaque v-if="destaques[2]" :leilao-init="destaques[2]"/>
                        <card-leilao-destaque v-if="destaques[0]" :leilao-init="destaques[0]"/>
                    </q-carousel-slide>
                    <!-- Tablet /-->

                    <!-- Mobile -->
                    <q-carousel-slide v-if="$q.screen.lt.md" :name="1"  :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row">
                        <card-leilao-destaque v-if="destaques[0]" :leilao-init="destaques[0]"/>
                    </q-carousel-slide>
                    <q-carousel-slide v-if="$q.screen.lt.md" :name="2"  :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row">
                        <card-leilao-destaque v-if="destaques[1]" :leilao-init="destaques[1]"/>
                    </q-carousel-slide>
                    <q-carousel-slide v-if="$q.screen.lt.md" :name="3"  :class="$q.screen.gt.xs ? 'q-px-xl' : ''" class="flex row">
                        <card-leilao-destaque v-if="destaques[2]" :leilao-init="destaques[2]"/>
                    </q-carousel-slide>
                    <!-- Mobile /-->

                </q-carousel>
            </div>

            <div class="flex row full-width q-mb-lg bg-primary" v-if="tipoCategoria === 'imobiliario'">

                <filtros-topo :filtros-atuais="filtros" :opcoes="filtrosOpcoes" :categoria="categoria"/>
            </div>
            <q-separator class="q-mb-lg" v-else/>

            <div
                id="listaprincipal"
                class="flex column justify-center content-stretch items-center">
                <h1 v-if="textosPagina.tipo || textosPagina.categoria || textosPagina.subCategoria" class="text-center q-my-none" :class="$q.screen.gt.xs ? 'text-h4' : 'text-h5'">
                    <q-breadcrumbs align="center">
                        <q-breadcrumbs-el v-if="textosPagina.tipo" :label="textosPagina.tipo" />
                        <q-breadcrumbs-el v-if="textosPagina.categoria" :label="textosPagina.categoria" />
                        <q-breadcrumbs-el v-if="textosPagina.subCategoria" :label="textosPagina.subCategoria" />
                    </q-breadcrumbs>
                </h1>
                <h1 v-else class="text-center q-my-none" :class="$q.screen.gt.xs ? 'text-h4' : 'text-h5'">
                    {{ (dominio_venda_direta_ativo) ? 'Todos os Veículos' : 'Todos os Leilões' }}
                </h1>
                <p  class="text-center q-mb-sm">
                    Nossas melhores oportunidades de negócios
                </p>
            </div>
            <div
                class="flex row justify-start content-stretch">
                <div class="flex column col-3" v-if="1===2">
                    <lista-categorias :contagens="contagens" :categorias="categorias" />
                    <div class="flex row full-width">
                        <filtros :filtros-atuais="filtros" :opcoes="filtrosOpcoes" />
                    </div>
                </div>
                <q-dialog maximized v-model="showDialog" v-else>
                    <q-card>
                        <q-toolbar class="bg-primary text-white">
                            <q-toolbar-title>
                                <q-icon name="mdi-filter" />
                                Filtros
                            </q-toolbar-title>
                            <q-btn flat round dense @click="showDialog = false">
                                <q-icon name="mdi-close" />
                            </q-btn>
                        </q-toolbar>
                        <lista-categorias v-model="showDialog" :contagens="contagens" :categorias="categorias" />
                    </q-card>
                </q-dialog>
                <q-page-sticky :offset="
                        (logado && cliente.status_cliente.identificador === 'AGUARDANDO_ANALISE') ? [18, 75] : [18, 18]
                    " position="bottom-left" style="z-index:1">
                    <q-btn
                        v-if="$q.screen.lt.lg"
                        color="primary"
                        fab icon="mdi-magnify"
                        label="filtrar"
                        @click="toggleFilter"
                    >
                    </q-btn>
                </q-page-sticky>
                <div class="flex column" :class="$q.screen.gt.md ? 'col-12' : 'col-12'">
                    <template v-for="comitente in lotesComitentesCarregados "
                              :key="comitente.id_comitente" :name="comitente.id_comitente">

                        <div class="row full-width q-pt-md q-pl-lg q-pb-none text-primary">

                            <div class="col-2" >
                                <div style="max-width: 80px; display: flex; justify-content: flex-start; align-items: center;">
                                    <q-img v-if=" comitente?.lotes[0]?.leilao?.comitente?.arquivo?.logoComitenteUrl"
                                           :src=" comitente?.lotes[0]?.leilao?.comitente?.arquivo?.logoComitenteUrl"
                                           fit="contain" style="height: 40px;"
                                    >
                                    </q-img>
                                </div>
                            </div>

                            <div class="col-10 q-pr-md" style="font-size: 18px; text-align: right;" :style="$q.screen.lt.lg ? 'padding-right: 30px' : 'padding-right: 25px'">
                                {{ comitente.nome_fantasia }}
                            </div>

                        </div>

                        <div class="flex row justify-center full-width q-pr-sm">
                            <lista-carousel-lotes-comitentes :lotes="comitente.lotes"/>
                        </div>
                    </template>

                    <div class="flex row full-width q-pt-md q-pl-lg text-primary" style="font-size: 18px;"
                         v-if="!textosPagina.tipo">
                        Todos os Leilões

                    </div>
                    <div class="flex row justify-center full-width q-px-sm" v-show="tab === 'leiloes'">
                        <q-infinite-scroll :disable="tab !== 'leiloes'" class="full-width" @load="loadDataLeiloes"
                                           :initial-index="0"
                                           :offset="850"
                                           ref="infiniteScrollLeiloes">
                            <lista-leiloes :leiloes="leiloesCarregados" v-if="tab === 'leiloes'" />
                            <template v-slot:loading>
                                <div class="full-width row justify-center q-my-md">
                                    <div class="column justify-center items-center content-center">
                                        <q-spinner-dots color="primary" size="60px" />
                                        <p class="text-primary">Carregando mais leilões...</p>
                                    </div>
                                </div>
                            </template>
                        </q-infinite-scroll>
                        <template v-if="tudoCarregadoLeiloes && leiloesCarregados.length === 0">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">Não encontramos nenhum resultado para os filtros fornecidos.</p>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="tudoCarregadoLeiloes">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">
                                        {{ (dominio_venda_direta_ativo) ? 'Estes são todos os veículos de venda direta que temos no site hoje para seus filtros!' : 'Estes são todos os leilões que temos no site hoje para seus filtros!' }}

                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="flex row justify-center full-width q-pa-sm" v-show="tab === 'lotes'">
                        <q-infinite-scroll
                            :disable="tab !== 'lotes'"
                            class="full-width"
                            @load="loadDataLotes"
                            :initial-index="0"
                            :offset="850"
                            ref="infiniteScrollLotes">
                            <lista-lotes-sem-leilao :lotes="lotesCarregados" v-if="tab === 'lotes'" />
                            <template v-slot:loading>
                                <div class="full-width row justify-center q-my-md">
                                    <div class="column justify-center items-center content-center">
                                        <q-spinner-dots color="primary" size="60px" />
                                        <p class="text-primary">Carregando mais lotes...</p>
                                    </div>
                                </div>
                            </template>
                        </q-infinite-scroll>
                        <template v-if="tudoCarregadoLotes && lotesCarregados.length === 0">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">Não encontramos nenhum resultado para os filtros fornecidos.</p>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="tudoCarregadoLotes">
                            <div class="full-width row justify-center q-my-md">
                                <div class="column justify-center items-center content-center">
                                    <p class="text-primary">Estes são todos os lotes que temos no site hoje para seus filtros!</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </q-page>
    </HomeLayout>
</template>
