<script setup>
import {reactive, watchEffect, ref, onMounted} from 'vue';
import { Inertia } from '@inertiajs/inertia';
import { defineProps } from 'vue';
import useRotas from '../../Composables/useRotas';

const route = useRotas();

const props = defineProps({
    filtrosAtuais: Object,
    opcoes: Object,
    categoria: String,
});

const filtros = reactive({
    precoDe: '',
    precoAte: '',
    localidade: '',
    imovel: {
        subcategoria: '',
        estado: '',
        cidade: '',
        bairro: '',
        locacao: ''
    }
});

const subcategoriaFiltro = ref();

const filtrar = () =>{
    if(subcategoriaFiltro?.value){

        Inertia.visit(
            route('tipos.categorias.subcategorias.subcategoria', {
                tipo: 'imobiliario',
                categoria: props.categoria,
                subcategoria: subcategoriaFiltro?.value
            }),
            {
                data: filtros
            }
        );
    }else{

        Inertia.visit(
            route('tipos.categorias.categoria', {
                tipo: 'imobiliario',
                categoria: props.categoria
            }),
            {
                data: filtros
            }
        );
    }

}

const disableCidade = ref(true);

watchEffect(() => {

    filtros.imovel.subcategoria = +props.filtrosAtuais?.imovel?.subcategoria || null;
    filtros.imovel.estado = +props.filtrosAtuais?.imovel?.estado || null;
    filtros.imovel.cidade = +props.filtrosAtuais?.imovel?.cidade || null;

})

const updateSubcategoria = () => {
    subcategoriaFiltro.value = (props.opcoes?.imobiliaria?.subcategorias)
        .find((subcategoria) => (subcategoria.id === filtros?.imovel?.subcategoria))?.slug;
}

const listaCidades = ref([]);

const updateEstado = async () => {

    if(!(+filtros.imovel.estado === +props.filtrosAtuais?.imovel?.estado)){
        filtros.imovel.cidade = null;
    }

    if(filtros?.imovel?.estado){

    const retornoCidades = await fetch(
        route('dadosHomefiltroCidades', { idEstado: filtros?.imovel?.estado }),
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
            },
        });

        listaCidades.value = await retornoCidades.json();

        disableCidade.value = false;

    }else{
        disableCidade.value = true;
    }
}

onMounted(() => {

    updateSubcategoria();

    if(!(+filtros.imovel.estado === +props.filtrosAtuais?.imovel?.estado)){
        filtros.imovel.cidade = null;
    }

    updateEstado();
});

</script>

<template>
    <div :class="['q-px-xs full-width', $q.screen.lt.md ? 'q-py-md' : 'q-py-xs']" >
        <q-card class="full-width bg-primary no-box-shadow">
            <q-form @submit="filtrar" class="col-12 flex row justify-center items-center">
                <div class="q-pa-sm col-12 col-xl-3 col-lg-3 col-md-3">

                    <!-- colocar opção todas as subcategorias -->
                    <q-select
                        :options="[{id: null, nome: 'Todas'}, ...(opcoes?.imobiliaria?.subcategorias || [])]"
                        option-label="nome"
                        option-value="id"
                        map-options
                        emit-value
                        v-model="filtros.imovel.subcategoria"
                        @update:model-value="updateSubcategoria"
                        bg-color="white"
                        class="full-width"
                        color="secondary"
                        dense
                        label="Categoria"
                        label-color="primary"
                        outlined
                    >
                    </q-select>
                </div>

                <div class="q-pa-sm col-12 col-xl-1 col-lg-1 col-md-2">
                    <q-select
                        :options="[{id: null, codigo_uf: 'Todos'}, ...(opcoes.imobiliaria.estados || [])]"
                        option-label="codigo_uf"
                        option-value="id"
                        map-options
                        emit-value
                        v-model="filtros.imovel.estado"
                        @update:model-value="updateEstado"
                        bg-color="white"
                        class="full-width"
                        color="secondary"
                        dense
                        label="UF"
                        label-color="primary"
                        outlined
                    >
                    </q-select>
                </div>

                <div class="q-pa-sm col-12 col-xl-3 col-lg-3 col-md-3">
                    <q-select
                        :options="[{id: null, nome: 'Todas'}, ...(listaCidades || opcoes.imobiliaria.cidades || [])]"
                        option-label="nome"
                        option-value="id"
                        map-options
                        emit-value
                        v-model="filtros.imovel.cidade"
                        bg-color="white"
                        class="full-width"
                        color="secondary"
                        dense
                        label="Cidade"
                        label-color="primary"
                        outlined
                        :disable="disableCidade"
                    >
                    </q-select>
                </div>

                <div class="        col-12 col-xl-1 col-lg-2 col-md-2 q-pa-sm">
                    <q-btn label="Buscar" color="secondary" class="full-width" @click="filtrar" size="16px"/>
                </div>

            </q-form>
        </q-card>
    </div>
</template>
